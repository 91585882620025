<template>
  <div class="contenido-colores">
    <div class="block-title"><h2>Selecciona tu color</h2></div>
    <div class="contenido-colores_cont">
      <div class="banner-imagen-colores">
        <img v-if="categorySlug === 'color-gel'" :src="base" class="fluid"/>
        <img v-else-if="categorySlug === 'glitter'" :src="base_glitter" class="fluid"/>
      </div>
      <div class="colores">
        <div v-for="color of colors" class="item" style="margin: 1px">
            <router-link :to="{ path: '/products/' + color.online_store_slug}">
              <img :src="getMediaPath(color.online_store_thumbnail)" class="img-block"/>
              <h3>{{ color.color }}</h3>
            </router-link>
            <div class="cont-block"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { colors } from '@/data/colors'; // check later
import LoadingMixin from '@/plugins/Common/LoadingMixin.js'

export default {
  name: "ColorSelector",
  props: ['categorySlug'],
  data() {
    return {
        colors: [],
        base: require('@/assets/img/common/frasco.png'),
        base_glitter: require('@/assets/img/common/glitter.png')
    };
  },
  components: {},
  methods: {
    load () {
      if (this.statusIsLoading()) return

      const query = this.$Models.Product.query()

      query.where('category_online_store_slug', this.categorySlug)

      query.where('online_store_thumbnail', '!=', 'null')

      query.forPage(1)

      this.colors = []

      this.statusLoading()
      query.get()
        .then(res => {
          return res
        })
        .then(res => {
          this.colors = res.items
          this.statusLoadingComplete()
        })
        .catch((err) => {
          this.statusLoadingFail()
          this.showError(err)
        })
    },
  },
  mixins: [LoadingMixin],
  watch: {
    categorySlug (nVal, oVal) {
      if (nVal !== oVal) {
        this.load()
      }
    }
  },
  mounted () {
    this.load()
  }
};
</script>
