<template>
    <div>
        <!-- footer Area -->
        <footer id="footer_one">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                        <!--div class="footer_one_widget">
                            <h3>SUSCRIBETE</h3>
                            <p>
                                Recibe tips, novedades, lanzamientos y
                                descuentos en tu email. Suscribe tu dirección de
                                correo aquí abajo.
                            </p>
                            <div id="mc_embed_signup" class="subscribe-form">
                                <form @submit.prevent="handleSubmit">
                                    <div class="mc-form">
                                        <input
                                            class="form-control"
                                            type="email"
                                            v-model="user.email"
                                            id="email"
                                            name="email"
                                            placeholder="Tu Email*"
                                            :class="{'is-invalid': submitted && $v.user.email.$error,}"
                                        />
                                        <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                                            <span v-if="!$v.user.email.required">Email es requerido</span>
                                            <span v-if="!$v.user.email.email">Emai es inválido</span>
                                        </div>
                                        <div class="clear">
                                            <button class="theme-btn-one btn_md" name="subscribe"><i class="icon-cursor"></i>Suscribirme</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div-->
                    </div>
                    <div
                        class="col-lg-5 col-md-5 col-sm-12 col-12"
                        style="background-color: #000"
                    >
                        <div class="center-element" style="width: 90%">
                            <h5 style="margin: 10px; color: white; text-align: center;">Tienda Principal</h5>
                            <p style="margin: 10px; color: white; text-align: center;">
                                {{company.location_address}}
                                <br />
                                <span v-if="company.c_workshift_1">{{company.c_workshift_1}}</span>
                                <br />
                                <span v-if="company.c_workshift_2">{{company.c_workshift_2}}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="background-color: #000">
                <div class="container">
                    <div class="row" style="background-color: #000">
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <img
                                :src="require('@/assets/img/logo-white.png')"
                                style="width: 50%; display: block; margin: auto;padding: 10px;"
                                class="img-reponsive img-rounded"
                            />
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div class="footer_one_widget">
                                <h3 style="color: white">INFORMACION</h3>
                                <ul style="color: white">
                                    <template v-if="company.external_links">
                                    <li v-if="company.show_menu_about">
                                        <a :href="company.external_links.about">Sobre nosotros</a>
                                    </li>
                                    </template>
                                    <template v-if="company.legal">
                                    <li v-if="company.legal.terms_and_conditions.text">
                                        <a href="/legal/terms_and_conditions">{{company.legal.terms_and_conditions.name}}</a>
                                    </li>
                                    <li v-if="company.legal.gift_card_policies.text">
                                        <a href="/legal/gift_card_policies">{{company.legal.gift_card_policies.name}}</a>
                                    </li>
                                    <li v-if="company.legal.payment_policies.text">
                                        <a href="/legal/payment_policies">{{company.legal.payment_policies.name}}</a>
                                    </li>
                                    <li v-if="company.legal.return_policy.text">
                                        <a href="/legal/return_policy">{{company.legal.return_policy.name}}</a>
                                    </li>
                                    <li v-if="company.legal.shipping_policies.text">
                                        <a href="/legal/shipping_policies">{{company.legal.shipping_policies.name}}</a>
                                    </li>
                                    <li v-if="company.legal.data_processing_policies.text">
                                        <a href="/legal/data_processing_policies">{{company.legal.data_processing_policies.name}}</a>
                                    </li>
                                    <li v-if="company.legal.warranty_policy.text">
                                        <a href="/legal/warranty_policy">{{company.legal.warranty_policy.name}}</a>
                                    </li>
                                    <li v-if="company.legal.returns_retracto_policy.text">
                                        <a href="/legal/returns_retracto_policy">{{company.legal.returns_retracto_policy.name}}</a>
                                    </li>
                                    <li v-if="company.legal.exchange_policy.text">
                                        <a href="/legal/exchange_policy">{{company.legal.exchange_policy.name}}</a>
                                    </li>
                                    </template>
                                    <!--li>
                                        <a href="/">Politicas de privacidad</a>
                                    </li-->
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div v-for="cLocation in company.c_locations" class="footer_one_widget">
                                <h5 style="margin: 10px; color: white">
                                    {{cLocation.name}}
                                </h5>
                                <p style="margin: 10px; color: white">
                                    {{cLocation.dir}}
                                </p>
                            </div>
                            <div style="color: white" v-if="company.complaints_book_url" class="footer_one_widget text-center">
                                <a :href="company.complaints_book_url">
                                    <img style="width: 200px;" src="@/assets/img/libro-reclamaciones.png" />
                                    <br>
                                    Libro de Reclamaciones
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div v-for="cSocialNetwork in company.c_social_networks" class="footer_one_widget">
                                <h5 style="color: white">
                                    {{cSocialNetwork.name}}
                                </h5>
                                <div
                                    class="footer_left_side_icon"
                                    style="color: white"
                                >
                                    <ul>
                                        <li>
                                            <a :href="cSocialNetwork.facebook"><i class="fab fa-facebook-f"></i></a>
                                        </li>
                                        <li>
                                            <a :href="cSocialNetwork.instagram"><i class="fab fa-instagram"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <!-- CopyRight Area -->
        <section id="copyright_one">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="copyright_left">
                            <h6>© Copyright 2025 <span>Organic Nails</span></h6>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
    data() {
        return {
            // Breadcrumb Items Data
            breadcrumbItems: [
                {
                    text: "Inicio",
                    to: "/",
                },
                {
                    text: "COntacto",
                },
            ],
            // Form Validation
            user: {
                email: "",
            },
            submitted: false,
        };
    },
    computed: {
        ...mapState({
            company: (state) => state.general.info,
        }),
    },
    /*
    validations: {
        user: {
            email: { required, email },
        },
    },
    */
    methods: {
        /*
        handleSubmit(e) {
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            alert("");
        },
        */
    },
};
</script>
